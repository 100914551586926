import React, { FC } from 'react';

import './FooterMail.scss';

const FooterMail: FC<PageContent.MailType> = ({ mail }) => (
  <div className="footer__mail" data-testid="footerMail">
    {mail ? (
      <a href={`mailto:${mail}`} className="footer__mail__link">
        {mail}
      </a>
    ) : null}
  </div>
);

export default FooterMail;

import React, { useState } from 'react';
import { Link } from 'gatsby';

import Button from 'common/Button';

import './Navigation.scss';

const Navigation = ({ navigationLinks, navigationAriaLabel }: PageContent.NavigationType) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div
        className={`btn--wrapper ${isVisible ? 'active' : ''}`}
        data-testid="navigation"
        onClick={() => setIsVisible(!isVisible)}
        role="button"
        tabIndex={0}
        onKeyDown={() => setIsVisible(!isVisible)}
      >
        <Button className="nav-btn" ariaLabel={navigationAriaLabel} />
      </div>
      {isVisible ? (
        <nav className="navigation-links">
          {navigationLinks?.map(({ link, label }) => {
            const { url } = link[0];

            return (
              <Link to={url} className="navigation-link" key={label}>
                {label}
              </Link>
            );
          })}
        </nav>
      ) : null}
    </>
  );
};

export default Navigation;

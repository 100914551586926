import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SocialMedia from '../SocialMedia';
import FooterCopyright from './FooterCopyright';
import FooterLinks from './FooterLinks';
import FooterMail from './FooterMail';

import './Footer.scss';

const Footer: FC = () => {
  const {
    allFooter: { nodes },
  } = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          copyright
          mail
          links {
            name
            url
          }
          socialMediaLinks {
            icon
            link {
              icon
              name
              url
            }
            iconColor
          }
        }
      }
    }
  `);

  const { copyright, mail, links, socialMediaLinks } = nodes[0];

  return (
    <footer data-testid="footer" className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <FooterMail mail={mail} />
          <SocialMedia socialMediaLinks={socialMediaLinks} />
        </div>
        <div className="footer__bottom">
          <FooterLinks links={links} />
          <FooterCopyright copyright={copyright} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import Button from 'common/Button';
import Logo from 'common/Logo';

import Navigation from './Navigation';

import './Header.scss';

const Header = () => {
  const {
    allHeader: { nodes },
  } = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          button {
            ariaLabel
            backgroundColor
            label
            link {
              url
            }
            size
          }
          navigation {
            navigationButton {
              ariaLabel
            }
            navigationLinks {
              label
              link {
                url
              }
            }
          }
        }
      }
    }
  `);

  const { button, navigation } = nodes[0];
  const { ariaLabel, backgroundColor, label, size, link } = button[0];
  const { url } = link;
  const { navigationLinks, navigationButton } = navigation[0];
  const { ariaLabel: navigationAriaLabel } = navigationButton;

  return (
    <header className="header">
      <Logo />
      <Button
        className="btn--sign-up"
        ariaLabel={ariaLabel}
        backgroundColor={backgroundColor}
        size={size}
        type="button"
        onClick={() => navigate(url)}
        label={label}
      />
      <Navigation {...{ navigationLinks, navigationAriaLabel }} />
    </header>
  );
};

export default Header;

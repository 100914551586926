import React, { FC } from 'react';
import { Link } from 'gatsby';

import './FooterLinks.scss';

const FooterLinks: FC<PageContent.FooterLinksType> = ({ links }) => (
  <nav data-testid="footerLinks">
    {links.map(({ name, url }) => (
      <Link to={url} key={name} aria-label={name} className="footer__link">
        {name}
      </Link>
    ))}
  </nav>
);

export default FooterLinks;

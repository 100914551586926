import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import './FooterCopyright.scss';

const FooterCopyright: FC<PageContent.CopyrightType> = ({ copyright }) => (
  <div className="footer__copyright" data-testid="footerCopyright">
    {copyright ? <DangerouslySetInnerHtml html={copyright} /> : null}
  </div>
);

export default FooterCopyright;

import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import './Button.scss';

const Button = ({
  label,
  backgroundColor,
  size,
  className,
  ariaLabel,
  onClick,
  type = 'button',
  disabled,
}: PageContent.ButtonType) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    aria-label={ariaLabel}
    onClick={onClick}
    className={classNames('btn', className, {
      [`btn--${backgroundColor}`]: backgroundColor,
      [`btn--${size}`]: size,
    })}
    disabled={disabled}
  >
    {label}
  </button>
);

export const ButtonType = graphql`
  fragment ButtonType on ButtonType {
    label
    ariaLabel
    backgroundColor
    size
  }
`;

export default Button;

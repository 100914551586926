import React, { FC } from 'react';
import classNames from 'classnames';

import './SocialMedia.scss';

export const SocialMedia: FC<PageContent.SocialMediaType> = ({ socialMediaLinks }) => (
  <section className="social-media" data-testid="socialMedia">
    {socialMediaLinks?.map(({ icon, link, iconColor }) => (
      <a
        href={link[0].url}
        target="_blank"
        rel="noreferrer noopener"
        aria-label={link[0].name}
        key={icon}
      >
        <i
          className={classNames('social-media__icon', {
            [`social-media__icon--${iconColor}`]: iconColor,
            [`fab fa-${icon}`]: icon,
          })}
        />
      </a>
    ))}
  </section>
);

export default SocialMedia;

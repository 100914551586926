import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import Image from 'common/Image';

const Logo: FC = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allBrandSettings: { nodes: brandSettingsNodes },
    allHomepage: { nodes: homepageNodes },
  } = useStaticQuery(graphql`
    {
      allBrandSettings {
        nodes {
          brandName
          brandLogo {
            ...UmbracoImage
          }
          lang
        }
      }
      allHomepage {
        nodes {
          url
          lang
        }
      }
    }
  `);

  const { brandLogo } = brandSettingsNodes.find(({ lang }) => lang === currentLangCode);
  const { url } = homepageNodes.find(({ lang }) => lang === currentLangCode);

  return (
    <Link to={url}>
      <Image imageData={brandLogo} alt={brandLogo.altText} className="logo__img" />
    </Link>
  );
};

export default Logo;
